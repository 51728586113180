import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Joao from './Joao';

import shadow from 'assets/shadow.png';

const Illustration = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  height: 220px;
  width: 100%;

  overflow: hidden;

  @media only screen and (max-width: 992px) {
    align-items: normal;
    margin-top: 60px;
  }
`;

const Shadow = styled.img`
  image-rendering: pixelated;
  width: 96px;
`;

const BOTTOM_IN_PX = 5;

const ShadowContainer = styled.div`
  position: absolute;
  z-index: 1;
  bottom: ${BOTTOM_IN_PX - 15}px;
`;

const CharacterContainer = styled.div`
  position: absolute;
  cursor: pointer;
  user-select: none;
  z-index: 2;
  bottom: ${BOTTOM_IN_PX}px;
`;

type Props = {
  remoteJumping?: boolean;
};

export default ({ remoteJumping = false }: Props) => {
  const [jumping, setJumping] = useState(false);

  function onTap() {
    jump();
  }

  function jump() {
    setJumping(true);
    setTimeout(() => setJumping(false), 700);
  }

  useEffect(() => {
    if (remoteJumping) {
      jump();
    }
  }, [remoteJumping]);

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if (event.key === ' ') {
        jump();
      }
    });
  }, []);

  return (
    <Illustration>
      <CharacterContainer onClick={onTap}>
        <Joao jump={jumping} />
      </CharacterContainer>
      <ShadowContainer>
        <Shadow src={shadow} />
      </ShadowContainer>
    </Illustration>
  );
};
