import React from 'react';
import styled from 'styled-components';

import gmail from 'assets/pixel-social-icons/gmail.png';
import linkedin from 'assets/pixel-social-icons/linkedin.png';
import x from 'assets/pixel-social-icons/x.png';
import instagram from 'assets/pixel-social-icons/instagram.png';
import github from 'assets/pixel-social-icons/github.png';

const SocialNetworks = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;

  @media (max-width: 992px) {
    flex-direction: row;
  }
`;

const Icon = styled.a`
  width: 35px;
  height: 35px;

  margin: 4px;
  background-size: cover;
  image-rendering: pixelated;
  cursor: pointer;
`;

const Github = styled(Icon)`
  background-image: url(${github});
`;

const Gmail = styled(Icon)`
  background-image: url(${gmail});
`;

const LinkedIn = styled(Icon)`
  background-image: url(${linkedin});
`;

const Instagram = styled(Icon)`
  background-image: url(${instagram});
`;

const X = styled(Icon)`
  background-image: url(${x});
`;

export default () => (
  <SocialNetworks>
    {/*<StackOverflow href="https://stackoverflow.com/users/1206767/jmartins" target="_blank" />*/}
    <LinkedIn href="https://www.linkedin.com/in/jotamartins/" target="_blank" />
    <Instagram href="https://www.instagram.com/jotavmartins/" target="_blank" />
    <X href="https://twitter.com/jotamartins" target="_blank" />
    <Gmail href="mailto:joaovitor.martins@gmail.com" target="_blank" />
    <Github href="https://github.com/jvmartins" target="_blank" />
  </SocialNetworks>
);
