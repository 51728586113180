import saymyname from 'assets/projects-images/saymyname.png';
import nexttoaperson from 'assets/projects-images/nexttoaperson.png';
import savethedate from 'assets/projects-images/savethedate.png';
import newsletterfyi from 'assets/projects-images/newsletter-fyi.png';
import jacaretracker from 'assets/projects-images/jacaretracker.png';
import halfwaypint from 'assets/projects-images/halfwaypint.png';
import artstories from 'assets/projects-images/artstories.png';
import techtok from 'assets/projects-images/techtok.png';
import houve from 'assets/projects-images/houve.png';

export type Project = {
  name: string;
  link: string;
  label: string;
  image: any;
};

export const projects: Project[] = [
  {
    name: 'A 5-minute read to stay up to date with the relentless changes in the tech world, curated from the most popular news sources into nice-looking stories.',
    label: 'TechTok Daily News & Newsletter',
    link: 'https://techtok.today/?utm_source=portfolio',
    image: techtok,
  },
  {
    name: '20k+ users. Names matter. Try harder to say your colleagues names right, use this tool. Golden Kitty Awards by Product Hunt in 2021',
    label: 'saymyname.io',
    link: 'https://saymyname.io/cards/joaomartins',
    image: saymyname,
  },
  {
    name: 'One-stop shop collection of tools for newsletter creators.',
    label: 'Newsletter Hub FYI',
    link: 'https://newsletterhub.fyi',
    image: newsletterfyi,
  },
  {
    name: 'You have a podcast and awesome audio content? We make you look great to brands and build your SEO from it.',
    label: 'Houve.co - Podcast SEO and Branding',
    link: 'https://houve.co',
    image: houve,
  },
  {
    name: 'Art Stories. Collecting grandma’s art gallery, enriching painting with AI, displaying gallery in short sentences from life stories.',
    label: 'Art Stories',
    link: 'https://mariamagdalena.art',
    image: artstories,
  },
  {
    name: 'Over 4 million users and $30k in donations for an environmental cause. Someone needed to count the number of people turning into alligators.',
    link: 'https://jacaretracker.org/en',
    label: 'Jacaré Tracker',
    image: jacaretracker,
  },
  {
    name: 'As my wedding guest, you first need to play my game to save the date',
    link: 'https://savethedate.deboraejoao.com',
    label: 'Save the Date!',
    image: savethedate,
  },
  {
    name: "Meet your friend halfway in London for a pint. What's the best closest pub in between post codes?",
    link: 'https://halfwaypint.london',
    label: 'halfwaypint.london',
    image: halfwaypint,
  },
  {
    name: "Have you ever bought anything online that wasn't exactly the size you were expecting?",
    link: 'https://nexttoaperson.com',
    label: 'Next to a Person',
    image: nexttoaperson,
  },
  // {
  //   name: 'When moving around London in a group, is it cheaper to get an Uber or the Tube?',
  //   label: 'Tuber',
  //   link: 'https://tuber.london',
  //   image: tuber,
  // },
];
