import styled from 'styled-components';

import { getCalApi } from '@calcom/embed-react';
import { useEffect } from 'react';
import { fonts } from 'fonts';
import CallButton from 'components/CallButton';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  margin: 0 auto;
  max-width: 70%;

  padding: 20px 0 30px;

  @media (max-width: 992px) {
    margin: 0 auto;
    max-width: 85%;
    padding: 50px 0 0;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-size: ${fonts.medium};
  font-weight: bold;
  text-align: center;
  margin: 0 auto 20px;

  max-width: 600px;

  @media only screen and (max-width: 768px) {
    font-size: ${fonts.medium};
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 1px 1px 2px 1px #181818;
  width: 100%;
  padding: 16px;
  font-size: 14px;
  gap: 8px;
  border-radius: 12px;
  max-width: 45%;

  ul {
    list-style: none;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
  }

  @media (max-width: 992px) {
    width: 100%;
    max-width: none;
  }
`;

const PricingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 8px;
`;

const OfferWrapper = styled.div`
  max-width: 900px;
  font-size: 14px;
`;

const ListItems = styled.ul`
  list-style-type: none;
  list-style-position: inside;
  margin: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export default () => {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal('ui', { styles: { branding: { brandColor: '#000000' } }, hideEventTypeDetails: false, layout: 'month_view' });
    })();
  }, []);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Content>
        <Info>
          <Title>How about a cost-effective expert hire for your project?</Title>
          <ListItems>
            <li>
              ☕️ <strong>Offload your software engineering work</strong>: new microservices, re-structuring your
              front-end, create your first AI pipeline and much more.
            </li>
            <li>
              🚀 Build the <strong>first & best minimum loveable version of your product</strong>. Close that investment
              or bootstrap with expert technical direction, strategic AI and product advice.
            </li>
          </ListItems>
        </Info>

        <OfferWrapper>
          <PricingWrapper>
            <Card>
              <h4>👨🏼‍🎨 Light Touch</h4>
              <span>
                Light touch guidance, hands-on coding, architecture, MVP building, ADRs, and prototypes.{' '}
                <strong>Ideal for early stage founders, seeded and pre-seeded startups</strong>.
              </span>
              <ul>
                <li>
                  <span>🚀</span> MVP Building with light touch guidance
                </li>
              </ul>
            </Card>
            <Card>
              <h4>🤿 Offload Work</h4>
              <span>
                Deep dive into your product, architecture, codebase, team, processes. Ideal for{' '}
                <strong>startups and enterprise</strong>.
              </span>
              <ul>
                <li>
                  <span>🚀</span> Feature planning, Refactoring, New Services, AI integrations
                </li>
              </ul>
            </Card>
          </PricingWrapper>
        </OfferWrapper>
        <CallButton />
      </Content>
    </div>
  );
};
