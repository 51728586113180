import styled from 'styled-components';
import calendar from 'assets/calendar.png';

const CallButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  margin-top: 16px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.3);
  border: 2px solid black;
  color: var(--primary);
  border-radius: 8px;
  cursor: pointer;
  transition: all 250ms ease;

  &:hover {
    scale: 1.05;
  }
`;

type Props = {
  onClick?: () => void;
};

export default ({ onClick }: Props) => {
  return (
    <CallButton
      onClick={() => {
        if (onClick) onClick();
      }}
      data-cal-namespace=""
      data-cal-link="jvmartins/let-s-build-together"
      data-cal-config='{"layout":"month_view"}'
    >
      <img src={calendar} width={28} style={{ marginRight: '6px' }}></img>
      Book an Intro
    </CallButton>
  );
};
