import styled from 'styled-components';

import adplist from '../assets/adplist.png';
import saymyname from '../assets/saymyname.png';
import spotify from '../assets/spotify.png';
import featurecreeps from '../assets/feature-creeps.png';

const Career = styled.div`
  p {
    font-size: 16px;
  }
  max-width: 70%;
  padding: 0 16px;
  margin: auto;
  text-align: center;

  @media (max-width: 992px) {
    max-width: 85%;
  }
`;

const Logos = styled.div`
  margin: 32px auto;
  display: grid;
  align-items: center;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  gap: 24px;
  max-width: 500px;
`;

const Logo = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const SayMyNameLogo = styled.div`
  background-color: white;
  align-items: center;
  display: flex;
  font-size: 13px;
  letter-spacing: 0.6px;
  padding: 4px 10px 5px 6px;
  border-radius: 6px;
  border: 1px solid #58e3b8;
  box-shadow: 3px 3px 1px 0px #58e3b8;

  img {
    width: 36px;
  }

  span {
    margin-left: 4px;
    color: #58e3b8;
    font-weight: bold;
  }
`;

const CareerTitle = styled.div`
  font-size: 12px;
  p {
    font-size: 12px;
  }
`;

const SectionTitle = styled.h2`
  display: flex;
  justify-content: center;

  margin-bottom: 30px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 60px;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default () => {
  return (
    <Career id="career">
      <SectionTitle>Career</SectionTitle>
      <Summary>
        <p>
          Previously at Spotify, currently developing an AI-powered name pronunciation tool for diversity and inclusion
          initiatives in large organizations. Also helping newsletter curators automate workflows and founders build
          their MVPs leveraging the latest developments in machine learning.
        </p>
        <p>
          Over 4 million people have used my inventions. I've built products that focus on solving day-to-day problems,
          and have a social impact, products for the creator economy, diversity & inclusion and beyond. I’m an active
          mentor on ADPList, with over 680 minutes and mentees from 10 different countries, and a co-host in the Feature
          Creeps podcast.
        </p>
        <p>
          As a Staff-level Engineer, I’ve led enterprise-level projects such as live collaboration for music creation
          and new mobile apps from scratch and significant re-architecture challenges. I was for many times responsible
          for productivity improvements and managing delivery efforts in several teams in London startups and later at
          Spotify.
        </p>
      </Summary>
      <Logos>
        <Logo>
          <Image src={spotify} style={{ minWidth: '120px' }} />
          <CareerTitle>Senior Software Engineer</CareerTitle>
        </Logo>
        <Logo>
          <SayMyNameLogo>
            <Image src={saymyname} />
            <span>SAY MY NAME</span>
          </SayMyNameLogo>
          <CareerTitle>Co-Founder</CareerTitle>
        </Logo>
        <Logo href="https://houve.co/featurecreeps" target="_blank">
          <Image src={featurecreeps} />
          <CareerTitle>
            <p>Feature Creeps Podcast</p> Co-Host
          </CareerTitle>
        </Logo>
        <Logo>
          <Image src={adplist} style={{ minWidth: '120px' }} />
          <CareerTitle>Mentor</CareerTitle>
        </Logo>
      </Logos>
    </Career>
  );
};
