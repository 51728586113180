import React from 'react';
import styled from 'styled-components';

import joaoSprites from 'assets/joao-sprites.png';

const properties = {
  spritesSizeSquarePx: 196,
  jumpSpeedMs: 550,
  jumpReturnMs: 600,
  jumpHeightPx: 90,
};

const JoaoDefault = styled.div<{ jump: boolean }>`
  width: ${properties.spritesSizeSquarePx * 0.75}px;
  
  image-rendering: pixelated;
  background: url(${joaoSprites});
  
  background-size: ${properties.spritesSizeSquarePx * 3}px ${properties.spritesSizeSquarePx * 3}px;
  background-position: ${(props) => props.jump ? `${properties.spritesSizeSquarePx * 0.75}px -100px` : `0 -100px;`};
  
  height: ${(props) => props.jump ? `${properties.spritesSizeSquarePx * 1.1}px` : `${properties.spritesSizeSquarePx}px` };

  transition: height 0.5s ease;
`;

const JoaoJump = styled(JoaoDefault)`
  background-position: ${properties.spritesSizeSquarePx * 0.75}px 0;
  height: ${properties.spritesSizeSquarePx * 1.7}px;
`;

const JoaoStill = styled(JoaoDefault)`
  background-position: 0 -100px;
`;

type JoaoProps = {
  jump?: boolean;
};

export default ({ jump = false }: JoaoProps) => {
  return <JoaoDefault jump={jump}/>;
};
