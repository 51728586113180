import styled from 'styled-components';

import FixedClouds from 'components/FixedClouds';
import SocialNetworksPixel from 'components/SocialNetworksPixel';
import Slides from 'components/Slides';
import OnePager from 'pages/OnePager';

import leftButton from 'assets/left-button.png';
import rightButton from 'assets/right-button.png';
import { BrowserRouter } from 'react-router-dom';

const Container = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  z-index: 1;
`;

const SocialNetworksContainer = styled.div`
  position: fixed;
  padding: 24px 0 0 24px;

  @media (max-width: 992px) {
    position: sticky;
  }

  z-index: 4;
`;

const MobileIndication = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  z-index: 4;
`;

const Swap = styled.img`
  height: 50px;
  margin: 16px;
  cursor: pointer;

  @media (min-width: 520px) {
    display: none;
  }
`;

export default () => {
  return (
    <>
      <BrowserRouter>
        <FixedClouds />
        <SocialNetworksContainer>
          <SocialNetworksPixel />
        </SocialNetworksContainer>
        {/* <Slides></Slides> */}
        <OnePager></OnePager>
      </BrowserRouter>
    </>
  );
};
