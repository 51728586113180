import React from 'react';
import styled from 'styled-components';

import clouds from 'assets/cloud.png';

const CloudsContainer: any = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;

  width: 100%;
  height: 100px;
  z-index: 3;
`;

const Clouds: any = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  height: 100px;
  width: 3000px;

  background-repeat: repeat-x;
  background-image: url(${clouds});
  background-position: left;
  background-size: contain;

  animation: move 500s linear;
  transform: translateX(-2000px);
  image-rendering: pixelated;

  @keyframes move {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-2000px);
    }
  }
`;

export default () => {
  return (
    <CloudsContainer>
      <Clouds src={clouds} />
    </CloudsContainer>
  );
};
