import React, { useState } from 'react';
import styled from 'styled-components';

import { getCalApi } from '@calcom/embed-react';
import { useEffect } from 'react';

import CharacterAnimation from 'components/character/CharacterAnimation';
import joao from 'assets/joao.jpg';
import x from 'assets/pixel-social-icons/x.png';
import linkedin from 'assets/pixel-social-icons/linkedin.png';
import CallButton from 'components/CallButton';
import { fonts } from 'fonts';

const Content = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;

  margin: 0 auto;
  max-width: 70%;

  @media (max-width: 992px) {
    margin: 0 auto;
    max-width: 85%;
    flex-direction: column;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Name = styled.h1`
  font-size: ${fonts.large};
  margin: 0;

  @media only screen and (max-width: 992px) {
    font-size: ${fonts.mediumLarge};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${fonts.medium};
  }
`;

const Title = styled.h2`
  font-size: ${fonts.medium};
  margin: 0;

  @media only screen and (max-width: 992px) {
    font-size: ${fonts.regularLarge};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${fonts.regular};
  }
`;

const Description = styled.div`
  margin: 8px 0 16px 0;
`;

const Image = styled.img`
  width: 100%;
  max-width: 80px;
  border-radius: 100%;
  box-shadow: 0px 1px 3px 1px #e4cc13;
`;

const Teaser = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;

  margin-bottom: 12px;
  gap: 16px;
`;

const TeaserContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 2px;
  text-decoration: none;
  color: black;
`;

const X = styled.img`
  background-image: url(${x});
  width: 20px;
  height: 20px;

  margin: 4px;
  background-size: cover;
  image-rendering: pixelated;
  cursor: pointer;
`;

const LinkedIn = styled.img`
  background-image: url(${linkedin});
  width: 20px;
  height: 20px;

  margin: 4px;
  background-size: cover;
  image-rendering: pixelated;
  cursor: pointer;
`;

export default () => {
  const [remoteJumping, setRemoteJumping] = useState(false);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({});
      cal('ui', { styles: { branding: { brandColor: '#000000' } }, hideEventTypeDetails: false, layout: 'month_view' });
    })();
  }, []);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Content>
        <Info>
          <Teaser>
            <Image src={joao} />
            <TeaserContent>
              <SocialLink href="https://x.com/jotamartins" target="_blank">
                <X /> Follow
              </SocialLink>
              <SocialLink href="https://www.linkedin.com/in/jotamartins/" target="_blank">
                <LinkedIn /> Connect
              </SocialLink>
            </TeaserContent>
          </Teaser>
          <Name>João Vitor Martins</Name>
          <Title>Engineer • Inventor • Mentor</Title>
          <Description>
            Award-winning serial Founder, Staff Engineer, ex-Spotify, fishing net casting specialist, RAG crafter,
            pre-amateur surfer. I've reached over <strong>4 million people</strong> with all my creations.
          </Description>
          <CallButton
            onClick={() => {
              setRemoteJumping(true);
              setTimeout(() => setRemoteJumping(false), 700);
            }}
          />
        </Info>
        <CharacterAnimation remoteJumping={remoteJumping} />
      </Content>
    </div>
  );
};
