import React from 'react';

import { projects } from './allProjects';
import Career from './Career';
import Consultancy from './Consultancy';
import MainContent from './MainContent';
import Projects from './Projects';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 64px;

  align-items: center;
  justify-content: center;

  margin: 100px auto;
  max-width: 1400px;

  width: 100%;

  z-index: 1;

  @media (max-width: 600px) {
    margin: 60px auto;
  }
`;

type Props = {};

export default ({}: Props) => {
  return (
    <Container>
      <MainContent />
      <Projects projects={projects} />
      <Career />
      <Consultancy />
    </Container>
  );
};
