import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { Project } from './allProjects';

const Projects = styled.div`
  display: grid;

  grid-template-columns: auto auto;

  max-width: 70%;
  padding: 0 16px;

  column-gap: 20px;

  @media (max-width: 992px) {
    grid-template-columns: auto;
    padding: 0;
    max-width: 85%;
  }
`;

const ProjectCard = styled.a`
  display: flex;

  margin: 20px 10px 10px;
  overflow: hidden;
  text-decoration: none;

  cursor: pointer;
`;

const ProjectImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 110px;

  @media (max-width: 800px) {
    width: 70px;
    min-width: 70px;
  }
`;

const ProjectImage = styled.img`
  position: relative;
  border-radius: 10px;

  width: 80px;

  @media (max-width: 800px) {
    width: 60px;
  }
`;

const ProjectDescription = styled.div`
  font-size: 16px;
`;

const ProjectInfoContainer = styled.div`
  flex-grow: 1;
  margin-left: 20px;
`;

const ProjectLink = styled.div`
  font-weight: 600;
  cursor: pointer;
`;

const SectionTitle = styled.h2`
  display: flex;
  justify-content: center;
  align-self: center;

  margin-bottom: 30px;
`;

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

type ProjectsProps = {
  projects: Project[];
};

export default ({ projects }: ProjectsProps) => {
  return (
    <Container>
      <SectionTitle>Inventions</SectionTitle>
      <Projects id="inventions">
        {projects.map((project: any) => (
          <ProjectCard key={uuid()} target="_blank" href={project.link}>
            <ProjectImageContainer>
              <ProjectImage src={project.image} />
            </ProjectImageContainer>
            <ProjectInfoContainer>
              <ProjectLink>{project.label}</ProjectLink>
              <ProjectDescription>{project.name}</ProjectDescription>
            </ProjectInfoContainer>
          </ProjectCard>
        ))}
      </Projects>
    </Container>
  );
};
